import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-vertical-one',
  templateUrl: './section-vertical-one.component.html'
})

export class SectionVerticalOneComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: '',
    subTitle: 'Çözüm Odaklı Yaklaşımlar',
    // tslint:disable-next-line:max-line-length
    description: 'Giz Bilişim olarak, durmaksızın büyüyen ve karmaşıklaşan bilgi teknolojilerinde ihtiyaçlarınızı karşılamayı hedefliyoruz. '
  };

  // tslint:disable-next-line:max-line-length
  lists: any[] = ['Bilişim Uygulamaları', 'Bilgi Güvenliği Yönetimi', 'Siber Güvenlik Alanında Ar-Ge Projeleri', 'Eğitim ve Danışmanlık Hizmetleri'];

  constructor() { }

  ngOnInit() {
  }

}
