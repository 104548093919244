import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-features",
  templateUrl: "./features.component.html",
})
export class FeaturesComponent implements OnInit {
  titleSectionProp: any = {
    class: "pr-lg-5 text-left",
    title: "",
    subTitle: "Odak Noktalarımız",
    description:
      "Bilişim sistemleri ve uygulamaları için artan güvenlik risklerinin yönetimi ve etkilerinin en aza indirilmesi ve yeni koruma tekniklerinin geliştirilmesi için Ar-Ge yapılması özellikle yeni yöntemlerin ve bilimsel çalışmaların teknoloji ve ürüne dönüştürülmesi hayati öneme sahiptir.",
  };

  List: any[] = [
    {
      title: "Eğitim ve Danışmanlık",
      image: "./assets/images/fancybox/coworking.png",
    },
    { title: "İhtiyaçları Anlama", image: "./assets/images/fancybox/6-7.png" },
    {
      title: "Stratejik yönlendirme",
      image: "./assets/images/fancybox/6-5.png",
    },
    {
      title: "Performans Analizi",
      image: "./assets/images/fancybox/statistics.png",
    },
    {
      title: "Ar-Ge Projeleri",
      image: "./assets/images/fancybox/settings.png",
    },
    { title: "Trendleri Belirleme", image: "./assets/images/fancybox/6-8.png" },
    { title: "Bilimsel Çalışmalar", image: "./assets/images/fancybox/6-6.png" },
    {
      title: "Ürün geliştirme",
      image: "./assets/images/fancybox/algorithm.png",
    },
  ];
  constructor() {}

  ngOnInit() {}
}
