import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "anasayfa",
    loadChildren: "./anasayfa/anasayfa.module#AnasayfaModule",
  },
  { path: "urunler", loadChildren: "./urunler/urunler.module#UrunlerModule" },
  {
    path: "hakkimizda",
    loadChildren: "./hakkimizda/hakkimizda.module#HakkimizdaModule",
  },
  {
    path: "hizmetler",
    loadChildren: "./hizmetler/hizmetler.module#HizmetlerModule",
  },
  {
    path: "iletisim",
    loadChildren: "./iletisim/iletisim.module#IletisimModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
