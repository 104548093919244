<!-- Footer -->
<footer class="footer-one iq-bg-dark iq-over-dark-90" id="contact">
  <div class="container">
    <!-- Address -->
    <div class="footer-top">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 ">
          <p class="footer-logo"><img alt="img" src="{{ logoImg }}" width="160"></p>
          <p class="footer-logo">GİZ Bilişim, Bilgi Teknolojileri ve Siber Güvenlik alanlarında Ar-Ge, eğitim, proje ve danışmanlık faaliyetleri yürütmektedir.</p>
          <!--
          <ul class="info-share d-inline list-inline">
            <li class="list-inline-item"><a href="#"><i class="fa fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fa fa-twitter"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fa fa-instagram"></i></a></li>
          </ul>
          -->
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 mt-4 mt-lg-0 mt-md-0">
          <h4 class="footer-title">Menü</h4>
          <div class="menu-footer-menu-container">
            <ul class="menu">
              <li class="menu-item"><a routerLink="/anasayfa" aria-current="page">Anasayfa</a></li>
              <li class="menu-item"><a routerLink="/hakkimizda">Hakkımızda</a></li>
              <li class="menu-item"><a routerLink="/hizmetler">Hizmetler</a></li>
              <li class="menu-item"><a routerLink="/urunler">Ürünler</a></li>
            </ul>
          </div>
        </div>
        <!--
        <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <h4 class="footer-title iq-rmt-30">Hizmetler</h4>
          <div class="menu-footer-menu-container">
            <ul class="menu">
              <li class="menu-item"><a routerLink="/landing-page1" aria-current="page">Home</a></li>
              <li class="menu-item"><a routerLink="/blog">Blog</a></li>
              <li class="menu-item"><a routerLink="/pages/career">Careers</a></li>
              <li class="menu-item"><a routerLink="/pages/client">Clients</a></li>
              <li class="menu-item"><a routerLink="/services/artificial-intelligence">Service</a></li>
            </ul>
          </div>
        </div>
        -->
        <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <h4 class="footer-title iq-rmt-30">Bize ulaşın</h4>
          <ul class="iq-contact">
            <li><a href="tel:{{ contactInfo.contactNumber }}"><i class="fa fa-phone"></i><span>{{ contactInfo.contactNumber }}</span></a></li>
            <li><a href="mailto:{{ contactInfo.email }}"><i class="fa fa-envelope"></i><span>{{ contactInfo.email }}</span></a></li>
            <li></li>
            <li><a><i class="fa fa-home"></i><span>G.T.Ü. Teknopark, Cumhuriyet Mah. Şişecam Yolu Sok. No:42/1 Gebze/Kocaeli 41420</span></a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Address END -->
    <div class="copyright-footer">
      <div class="pt-3 pb-3">
        <div class="row flex-row-reverse justify-content-between">
          <div class="col-lg-12 col-md-12 text-lg-center text-md-center text-center"><span class="copyright">Copyright 2021 <a routerLink="/anasayfa">Giz Bilişim</a>. All Rights Reserved.</span></div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Footer End-->
