import { Component, HostListener, OnInit } from "@angular/core";
import * as $ from "jquery";

@Component({
  selector: "app-scroll-top",
  templateUrl: "./scroll-top.component.html",
  styles: [],
})
export class ScrollTopComponent implements OnInit {
  windowScrolled: boolean;

  constructor() {}

  ngOnInit() {}
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop > 100
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  scrollTop() {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      800
    );
  }
}
