<section class="position-relative">
  <img src="./assets/images/about-us/02.png" class="about-box-img" alt=""/>
  <div class="container">
    <div class="row">
      <div class="col-lg-6"></div>
      <div class="col-lg-6 wow fadeInUp align-items-center">
        <app-title-section
          [titleSectionProp]="titleSectionProp"
        ></app-title-section>
        <app-check-list [lists]="lists"></app-check-list>
        <div class="text-left mt-5">
          <a class="button button-icon" href="/hizmetler"><span class="btn-effect">Hizmetlerimiz</span></a>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br>
      </div>
    </div>
  </div>
</section>
