<!-- Header component start-->
<app-header
  [logoImg]="logoImage"
  [navItemList]="navItems"
  [contactInfo]="contactInfo"
></app-header>
<!-- Header component end-->
<!-- START REVOLUTION SLIDER -->
<app-revolution-slider1></app-revolution-slider1>
<!-- END REVOLUTION SLIDER -->
<!-- MainContent -->
<div class="main-content">
  <!-- About Us -->
  <app-section-vertical-one></app-section-vertical-one>
  <!-- About Us  End-->
  <!-- How it Works -->
  <app-section-three></app-section-three>
  <!-- How it Works End -->
  <!-- Feature -->
  <app-features></app-features>
  <!-- Feature End -->
</div>
<!-- MainContent End-->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->
