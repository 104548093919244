import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-three',
  templateUrl: './section-three.component.html'
})
export class SectionThreeComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: '',
    subTitle: 'Çalışma Alanlarımız'
  };

  circle1Data: any = {
    img: 'guvenlik-analizi.png',
    title: 'Bilgi Teknolojileri ve Siber Güvenlik',
    description: 'Bilgi teknolojileri ve siber güvenlik alanında yenilikçi çalışmalar yapıyoruz.',
    delay: '0.6s',
    positionClass: 'right',
    type:'consult-services'

  };

  circle2Data: any = {
    class: 'mt-4 mt-md-0',
    img: 'ar-ge.png',
    title: 'Ar-Ge Projeleri',
    description: 'Devlet destekli Ar-Ge projeleri geliştiriyor ve hizmetinize sunuyoruz.',
    delay: '1s',
    positionClass: 'right',
    type:'consult-services'

  };

  circle3Data: any = {
    class: 'mt-4 mt-md-0',
    img: 'egitim-danismanlik.png',
    title: 'Eğitim ve Danışmanlık',
    description: 'Çalışma alanlarımız ve Ar-Ge projelerinde eğitim ve danışmanlık hizmetleri veriyoruz.',
    delay: '1.4s',
    type:'consult-services'

  };

  constructor() { }

  ngOnInit() {
  }

}
