const topMenuBarItems = [
  {
    href: "/anasayfa",
    title: "Anasayfa",
  },
  {
    href: "/hakkimizda",
    title: "Hakkımızda",
  },
  {
    href: "/hizmetler",
    title: "Hizmetler  ",
    submenu: [
      {
        href: "/hizmetler/ar-ge-projeleri",
        title: "Ar-Ge Projeleri",
      },
      {
        href: "/hizmetler/guvenlik-analizi-ve-tasarimi",
        title: "Güvenlik Analizi ve Tasarımı",
      },
      {
        href: "/hizmetler/adli-delil-analizi",
        title: "Adli Delil Analizi",
      },
      {
        href: "/hizmetler/sizma-testleri",
        title: "Sızma Testleri",
      },
      {
        href: "/hizmetler/kimlik-dogrulama-sistemleri",
        title: "Kimlik Doğrulama Sistemleri",
      },
      {
        href: "/hizmetler/egitim-ve-danismanlik",
        title: "Eğitim ve Danışmanlık",
      },
    ],
  },
  {
    href: "/urunler",
    title: "Ürünler",
    submenu: [
      {
        href: "/urunler/efem",
        title: "EFEM Event Manager",
      }
    ],
  },
  {
    href: "/iletisim",
    title: "İletişim",
  },
];

const servicesMenu = [
  {
    href: "/hizmetler/ar-ge-projeleri",
    title: "Ar-Ge Projeleri",
  },
  {
    href: "/hizmetler/guvenlik-analizi-ve-tasarimi",
    title: "Güvenlik Analizi ve Tasarımı",
  },
  {
    href: "/hizmetler/adli-delil-analizi",
    title: "Adli Delil Analizi",
  },
  {
    href: "/hizmetler/sizma-testleri",
    title: "Sızma Testleri",
  },
  {
    href: "/hizmetler/kimlik-dogrulama-sistemleri",
    title: "Kimlik Doğrulama Sistemleri",
  },
  {
    href: "/hizmetler/egitim-ve-danismanlik",
    title: "Eğitim ve Danışmanlık",
  },
];

export { servicesMenu, topMenuBarItems };
